<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">VI</span>EW LOGS</h4>
       </CRow>
    <CCardBody>
    <!-- <div id="pointer" style="text-align:center">
          <label class="mt-1 ml-2"
           style="font-size: 20px; float: right; color:white"><b>New Offer</b></label>
    </div>-->
    <!-- <CCard v-if="user_status == 'false'" class="col-md-11 mt-2">
      <h5 style="color:red;">Your Account is not activated</h5>
      <label>please contact to Manager</label>
    </CCard> -->
        <CRow>
        <label class="col-md-11" style="">Use the View Logs to get all the data about a visit.
            Be sure to search for the fraudAvoid external_id. You can also
            consult it programmatically <CLink href="#/document/clickfinderinfo" >using our api</CLink></label>
        </CRow>
        <CRow>
          <CButtonGroup class="col-md-12">
        <CInput
            class="col-md-8 col-9"
            type= "text"
            size="sm"
            v-model="clickfind"
            require
            placeholder=""
            v-on:keyup.enter="onSubmit"
        ></CInput>
        <CButton class="col-md-1" style="height:30px;" size="sm" type="submit" @click="onSubmit()" variant="outline" color="success">Search</CButton>
       </CButtonGroup>
        </CRow>
        
       
      <CRow>
          <div class="data col-md-12 col-12 ml-4">
              <pre style="color:black;font-weight:900;" v-if="Object.values(clickdata).length>0">{{manageClickIdData(clickdata)}}</pre>
          </div>
      </CRow>
    </CCardBody>
    </CCard>
  </div>
</template>

<script>
// import swal from "sweetalert";
import ClickFinderService from '../../services/clickfinderControlService'
import SettingService from "../../services/settingControlService";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  components: {  },
  async mounted() {
    if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
    else{
    if (window.localStorage.getItem("user") == "null") {
      alert("Please Enter User Name and Password");
      this.$router.push("Login");
    } else {
      this.user_status = window.localStorage.getItem("status");
    }
    this.getReportOptions();
    }
  },
  data() {
    return {
        user_status:'',
        clickfind:'',
        clickdata:'',
        subMapData: {},
    } 
  },
  methods: {
   async onSubmit() {
    this.clickdata = '';
      if (!this.clickfind) {
        Swal.fire({title: 'Error!',text: 'Please enter valid External Id',icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
        return;
      }
      let find_data = {
          clickid: this.clickfind
      }
      let response = await ClickFinderService.clickfind(find_data)
      this.clickdata = '';
      if(response.result){
          this.clickdata = response.message
      }
      else{
        Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
      }
    },
    manageClickIdData(data){
      const newData = {};
      Object.keys(data).forEach((key) => {
        const newKey = this.subMapData[key] || key;
        newData[newKey] = data[key];
      });
      return newData;
    },
    async getReportOptions(){
    let response = await SettingService.getSubValue();
      if(response.result == true){
        this.subMapData = response.data.subValueMappping;
        this.subMapData.browse = 'Click';
        this.subMapData.accepted = 'Valid';
        this.subMapData.rejected = 'Invalid';
        this.subMapData.acceptedRate = 'Valid Rate';
        this.subMapData.rejectedRate = 'Invalid Rate';
      }
    }
  },
};
</script>

<style scoped>

h1 {
  font-size: 30px;
  color: #0b1218;
  margin-bottom: 24px;
}
.data {
  margin: 4px, 4px;
  margin-bottom: 20px;
  padding: 4px;
  width: auto;
  min-height: 650px;
  max-height: auto;
  background-color: #ffffff;
}
label {
  text-align: left;
  font-weight: 400;
  color: #6e7d8f;
  font-size: 12px;
}
.b_card {
  background-color: #fbf9f9;
}
.bg_page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  /*position: fixed;*/
  /*background: rgb(2, 0, 36);*/
  /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
}

.logout {
  padding: 4px;
  background-color: #ff374c;
  text-align: center;
  border-radius: 5px;
}
</style>
